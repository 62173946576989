import React    from 'react'
import './suturas.css'
import Header   from '../../components/Header'
import suturas  from '../../imgs/suturas.jpg'
import lans     from '../../imgs/lans1.jpg'



class Suturas extends React.Component{

    render(){

        return <React.Fragment>
                <Header heading="Eventos" subheading="Suturas do Amor" imageBackground={suturas}/>
                <section id="suturas">
                <h2><em>Suturas do Amor</em></h2>
                <h3>Lançamento oficial do livro de Rudêncio Morais, Falso Poeta</h3>
                <div className="row">
                    <div className="col span-1-of-2">
                        <img className="lans" src={lans} width="550px" alt="Suturas do Amor de Rudêncio Morais Falso Poeta"/>
                    </div>
                    <div className="col span-1-of-2">
                        <div style={{padding: "66.67% 0 0 0", position:"relative"}}>
                            <iframe id="showreel" src='https://vimeo.com/showcase/6354894/embed' allowFullScreen frameBorder='0' style={{position:'absolute', top:'5%', left:'25%', width:'50%', height:'50%'}}></iframe>
                        </div>
                    </div>
                </div>
                <p>Depois dos <strong>Dialetos do Amor</strong>, obra de estreia do escritor moçambicano <strong>Falso Poeta</strong>, pseudônimo de <strong>Rudêncio Morais</strong>, muito bem recebida pelos amantes da literatura e pela singularidade poética do Falso poeta, que numa linguagem prosaica e de intimidade profunda nos fala do amor no seu dialeto, foi lançada no dia 27 de Setembro de 2019 a obra <strong>Suturas do Amor</strong>. </p>
                <p>Um trabalho literário imbuído de forma e conteúdo, na qual o falso poeta, veste no amor a força redentora da própria existência enquanto homem e mulher, faz do amor, e das suas dores, embora vez por outra profundas, as suturas dos vazios da alma, um amor nos seus mimos, e na delicadeza dos mais íntimos e naturais perfumes africanos, o cheiro que se nos sai de dentro só quando o amor se nos quer percorrer com toda a sua fragrância e força.</p>
                <p>Em Suturas do Amor, o amor é esculpido no dorso do corpo de quem lê, pintado na palma da alma de quem se abre, e no pó das memórias de quem vimos amar e amamos, são então inalados os mais íntimos temperos de quem se entrega ao amor sendo a linha que suga no seu coser tudo que no amor um dia verteu, é simplesmente um degrau depois dos Dialetos do Amor, a cura de quem baloiça no tênue ventilar entre o amor e a existência.</p>
                <p>O evento decorreu no Salão Rústico dos <strong>Jardins Paloma</strong>, tendo como pano de fundo um eterno brinde ao amor, contando com recitações de Negro &amp; Rossana Malenda, Sannon e Mabote, na Guitarra e Percursão, nas vozes tivémos Júlia, Karen e Catepe, e perfomance de Benedito Xavier (Veveve). </p>
                <p>Rudêncio Morais nasceu no Distrito de Gurué, Província da Zambézia em 1987, e viveu em Quelimane. É licenciado em Geologia pela Universidade Eduardo Mondlane; e mestrado em Engenharia Geológica e de Minas, pela Universidade de Coimbra. O autor é apaixonado pelas letras e os seus interesses abrangem a filosofia e a poesia, os quais expressa através da escrita, explorando as várias esferas da vida e do "Eu Existencial".</p>   
                <p>Segundo <a href="https://www.worldliteraturetoday.org/author/susan-smith-nash" target="_blanl" rel="noopener noreferrer"><strong>Susan Nash</strong></a> da Universidade de Oklahoma nos Estudos Unidos, e colaboradora da <a href="https://www.worldliteraturetoday.org/" target="_blank" rel="noopener noreferrer"><strong>World Literature Today</strong></a>, ao adquirir um pseudónimo, o autor pretende libertar, através das palavras, a pluralidade dos estados de ser e de capturar os momentos de iluminação da materialidade da paixão humana.</p>

                <p>Esta abordagem já era conhecida na obra de Fernando Pessoa. Na mesma vertente, o autor W.B. Yeats referiu-se ao momento de escrever sob uma identidade diferente, como se estivesse escrevendo dentro de uma máscara; e tal como Pessoa, Yeats explorou a escrita automática, revelando assim as verdades ocultas da realidade, e do eu interior.</p>

                <p>O tema do amor e da perda amorosa são conjugados neste fluxo colorido de identidades, nas diferentes vozes e polifonias que invadem a obra, na canalização criativa de diferentes "Eus". Nesta obra, os poemas em prosa, típicos do autor, buscam representar os momentos de contacto entre as pessoas, a sutura da experiência, os sentidos da perda e do anseio, e finalmente, a ruptura.</p>

                <p>Existe ainda uma qualidade escultórica própria do estilo do autor, que já se observava de forma nascente na primeira obra "Dialetos do Amor", e que aqui irrompem qual friso de formas côncavas e convexas, onde o texto vira textura, aludindo à interseção dos corpos, edifícios e cores, produzindo uma experiência poética por vezes desconcertantemente íntima.</p>
                {/* Entrevista */}
                <div className="container">
                    <h2>Entrevista na Rádio Índico</h2>
                    <div className="row soundcloud">
                        <iframe width="65%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/692202664&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                </div>
                </div>
                {/* <div className="container video">
                    
                </div> */}
                
                </section>
        </React.Fragment>
    }
}

export default Suturas


