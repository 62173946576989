import React from 'react'
import './noticias.css'
import Header from '../../components/Header'
import NewsCard from './NewsCard'
// import { Link } from 'react-router-dom'
import background from '../../imgs/festivo.jpeg'
import acontece from '../../imgs/top-acontece.jpg'
import promo from '../../imgs/nydiscount-instagram.jpg'
import linkadosPic from '../../imgs/linkados-ad.jpg'
import fbEvent from '../../imgs/fb-event-cover.jpg'
import impactoEvent from '../../imgs/impactoposter.jpg'
import descontoinfantil from '../../imgs/descinfant.jpg'

class Noticias extends React.Component{

    state = {
        newsCards:[
            {
                id: 1,
                src: descontoinfantil,
                alt: "Descontos até 25% em todas as Festas Infantis e Babyshower!",
                link: "https://www.facebook.com/jardinspaloma/photos/pcb.626763608100864/626763404767551/?type=3&theater",
                heading:"DESCONTO Especial",
                subheading:"4 de Fevereiro 2020",
                description: "Oferecemos descontos até 25% no aluguer de espaço e decoração, ou festa na piscina!"
            },
            {
                id: 2,
                src: fbEvent,
                alt: "Lançamento do livro Suturas do Amor, de Rudêncio Morais, Falso Poeta",
                link: "#/noticias/suturas",
                heading:"'Suturas do Amor'",
                subheading:"27 de Setembro 2019, 17:30",
                description: "Lançamento da segunda publicação de Rudêncio Morais, autor Moçambicano"
            },
            {
                id: 3,
                src: impactoEvent,
                alt:"Segunda edição do Impacto, com atuações de DJs Moçambicanos e internacionais",
                link: "#/noticias/impacto",
                heading: "Impacto",
                subheading:"14 de Setembro 2019, 19:00",
                description:"Segunda edição do Impacto"
            },
            {
                id: 4,
                src: linkadosPic,
                alt: "linkados publicidade",
                link: "https://www.facebook.com/linkadosmedia/photos/a.2176650382606929/2271362443135722/?type=3&theater",
                heading:"Linkados Media",
                subheading:"Fevereiro 2019",
                description:"Anúncio na 3a edição dos Linkados Media"
            },
            {
                id: 5,
                src: promo,
                alt: "Promoção festiva nos Jardins Paloma",
                link: "https://www.facebook.com/jardinspaloma/photos/a.369620573815170/372031300240764/?type=3&theater",
                heading:"Promoção Festiva",
                subheading:"24 Dez. 2018 - 15 Fev. 2019",
                description: "Promoção especial de festas. ESGOTADO"
            },
            {
                id: 6,
                src: acontece,
                alt: "Jardins Paloma no TOP de espaço de eventos na Matola e Maputo",
                link: "http://acontece.co.mz/lugares/espacos-eventos/jardins-paloma",
                heading:"Acontece", 
                subheading:"TOP Espaços de Eventos",
                description: "Os Jardins Paloma foram escolhidos como um dos locais de eventos de referência em Maputo,pela plataforma Acontece" 
            }
        ]
    }
    render(){

        const {newsCards} = this.state

        return(
            <React.Fragment>
                <Header heading="Notícias" imageBackground={background}/>
                <div className="container-welcome">
                <div className="row">
                    <div className="col span-1-of-2 box">
                        <ul>
                        <li>SEMPRE ACTUAL</li>
                        <li></li>
                        <li>JARDINS PALOMA</li>
                        </ul>
                    </div>
                    <div className="col span-2-of-2 ">
                        <p>Fique a par de tudo o que acontece nos nossos espaços, e de todas as atualizações desde promoções, descontos festivos, festas, lançamentos, e artigos relacionados com
                            os nossos eventos. </p>
                    </div>
                
                </div>
            </div>
                <section id="noticias">
                    <div className="row">
                        <h2>AS ÚLTIMAS NOVIDADES</h2>
                      
                    </div>
                    {newsCards.map( newscard => (
                        <a href={newscard.link} target="_blank" rel="noopener noreferrer">
                            <NewsCard 
                                      picture={newscard.src}
                                      alt={newscard.alt}
                                      heading={newscard.heading}
                                      subheading={newscard.subheading}
                                      description={newscard.description}
                            />
                        </a>

                    ))};
                    
                </section>
            </React.Fragment>
        )
    }
}

export default Noticias