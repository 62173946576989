import React from 'react'
import {HashLink as Link} from 'react-router-hash-link'
import './intro.css'
import '../../vendors/grid.min.css'
import '../../vendors/normalize.min.css'
import Header from '../../components/Header'
import IntroSegment from './IntroSegments'
import background from '../../imgs/tables.jpg'
import rustico from '../../imgs/rustico-prof-crop2.jpg'
import tree from '../../imgs/tree.jpg'
import weddingCake from '../../imgs/wedding-cake.jpg'
import suturas from '../../imgs/lans1.jpg'
import impactoEvent from '../../imgs/impactoposter.jpg'

class Intro extends React.Component{

    render(){
        return(
            <React.Fragment>
                <Header heading="Jardins Paloma" subheading="Eventos" imageBackground={background} >
                </Header>
            
            <div className="container-welcome">
                <div className="row">
                    <div className="col span-1-of-2 box">
                        <ul>
                        <li>BEM-VINDO</li>
                        <li>WELCOME</li>
                        <li>JARDINS PALOMA</li>
                        </ul>
                    </div>
                    <div className="col span-2-of-2 ">
                        <p>Nos Jardins Paloma encontrará espaços de eventos e belíssimos jardins, um grande salão de festas, pavilhões, piscinas, sítio de bar e esplanada, parque infantil e estacionamento. 
                            Localizado na Matola, Moçambique, o Jardins Paloma dispõe de 1.5 hectares, e acolhe variados eventos - desde casamentos, festas de buffet, festa infantil, festa de 15 anos, baptizados, 
                            comemorações de fim de ano, cocktails, festas de empresa, eventos corporativos, eventos start up e team building, eventos de gala, reuniões ou apresentações de empresa, eventos escolares, entre muitos outros. </p>
                    </div>
                
                </div>
            </div>
            <section id="segment-of-three">
                    <div className="three-lg-imgs">
                    <IntroSegment   id="box-12"
                                    backgroundImage={tree} 
                                    title="ESPAÇOS"
                                    text="Encontrará espaços ao seu dispôr, pavilhões, um salão de festas, e jardins para fazer o seu evento."
                                    linkTo="/pavilhoes#header"/>

                    <IntroSegment   id="box-13"
                                    backgroundImage={rustico}
                                    title="EVENTOS"
                                    text="Acolhemos variados tipos de eventos, desde batizados, casamentos, congressos, reuniões, eventos corporativos, e mais!"
                                    linkTo="/eventos#header"/>

                    <IntroSegment   id="box-14"
                                    backgroundImage={weddingCake}
                                    title="RESERVAS"
                                    text="Temos quatro planos de reserva. Escolha o mais adequado ao evento que pretende realizar."
                                    linkTo="/reservas#header"/>

                    </div> 
                </section>
            <section id="news">
            <h2>EVENTOS</h2>
            <div className="row">
                    <div className="container-cartaz">
                        <div className="ui items">
                            {/* Suturas */}
                            <div className="item">
                                <div className="image">
                                    <img src={suturas} />
                                </div>
                                <div className="content">
                                    <a className="header" href="#/noticias/suturas">Suturas do Amor</a>
                                    <div className="meta">
                                        <span><strong>27 Setembro 2019</strong> 17:30</span>
                                    </div>
                                    <div className="description">
                                        <span>Lançamento da segunda obra de Rudêncio Morais, Falso Poeta. </span><br/>
                                        <span>Contará com recitações de Negro e Malenda, vozes de Sanon Murula, Júlia Morais, Karina, performance de Benedito Xavier(Veveve).</span><br/>
                                        <span>Ilustração de capa: Pedro Mourana.</span>
                                    </div>
                                    <div className="extra">
                                        <Link to="/noticias/suturas#header"><button className="maisinfo">Mais info</button></Link>
                                        <a href="https://www.facebook.com/events/2447426385337812/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-facebook"></ion-icon></a>
                                        <a href="https://www.instagram.com/p/B2wBStGpU-9/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-instagram"></ion-icon></a>
                                    </div>
                                </div>
                            </div>
                        {/* Impacto */}
                        <div className="item">
                            <div className="image">
                                <img src={impactoEvent} />
                            </div>
                            <div className="content">
                                <a className="header" href="#/noticias/impacto">Impacto II</a>
                            <div className="meta">
                                <span><strong>14 de Setembro 2019</strong> 19:00</span>
                            </div>
                            <div className="description">
                                <span>A segunda edição do IMPACTO chegou a Matola!</span><br/>
                                <span>Com atuações de DJ Trevor K Deep, DJ Cleyton Zimia, DJ Djuluka, Dj Damost, Dj FLasH, Erik ID, Kelvin Pedrinha</span><br/>
                                <span>Produção de XVM Entertainment, Picasso Eventos, Royaleza Real</span><br/>
                                <span>Apoio de 2M Cervejas, e RAM Multimédia</span>
                            </div>
                            <div className="extra">
                                <Link to="/noticias/impacto#header"><button className="maisinfo">Mais info</button></Link>
                                <a href="https://www.facebook.com/events/2349490811753692/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-facebook"></ion-icon></a>
                                <a href="https://www.instagram.com/p/B2OE3y0J1v8/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-instagram"></ion-icon></a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="fb-page" data-href="https://www.facebook.com/jardinspaloma/" data-tabs="timeline" data-width="300" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/jardinspaloma/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/jardinspaloma/">Jardins Paloma Eventos</a></blockquote></div>
            </div>
            </section>
          </React.Fragment>
        )
    }
}



export default Intro




