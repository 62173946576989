import React from 'react'
// import { Link } from 'react-router-dom'

const NewsCard = (props) => {
    return <div className="ui link cards">
            <div className="card">
            {/* <Link to={props.to}> */}
                <div className="image">
                    <img src={props.picture} alt={props.alt}/>
                </div>
                {/* </Link> */}
                <div className="content">
                    <div className="header">{props.heading}</div>
                    <div className="meta">
                        <p>{props.subheading}</p>
                    </div>
                    <div className="description">
                        {props.description}
                    </div>
                </div>
             
            </div>
        </div>
      
}

export default NewsCard