import React from 'react'
import {HashLink as Link} from 'react-router-hash-link'
import Header from '../../components/Header'
import background from '../../imgs/background.jpg'
import '../TermsConditions/termos.css'

class PrivacyPolicy extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Header heading="Jardins Paloma" subheading="Política de Privacidade" imageBackground={background} >
                </Header>
                {/* <div className="container-welcome">
                <div className="row">
                    <div className="col span-1-of-2 box">
                        <ul>
                        <li></li>
                        <li></li>
                        <li>JARDINS PALOMA</li>
                        </ul>
                    </div>
                    <div className="col span-2-of-2 "> 
                    </div>
                </div>
            </div> */}
            <section className="termos">
            {/* <h2>Política de Privacidade</h2> */}
                        <p className="first">Os Jardins Paloma Eventos (“Jardins Paloma”), enquanto responsáveis pelo tratamento, valorizam a privacidade do utilizador do Site (“Utilizador”) e 
                            cuidam da forma como os dados pessoais do Utilizador são tratados.
                            A presente Política de Privacidade é complementada pelos <Link to="/termos#header">Termos de Utilização</Link>.
                            Os Jardins Paloma aconselham a leitura desta Política de Privacidade e das futuras atualizações, a datas 
                            das quais será sempre anunciada no final desta página.</p>

                        <h5>1) Recolha de Dados</h5>
                        <p>Os dados pessoais recolhidos são tratados no estrito cumprimento da legislação aplicável. Os dados pessoais serão 
                            conservados de acordo com as respetivas finalidades e respeitando os prazos legais aplicáveis. Assim, e sempre 
                            que não exista uma exigência legal específica, os dados serão armazenados e conservados apenas pelo período adequado 
                            e na medida do necessário ao âmbito das finalidades para as quais foram recolhidos, exceto se for exercido, dentro 
                            dos limites legais, o direito de oposição, direito ao apagamento ou caso o Utilizador retire o consentimento.</p>

                        <h5>2) Divulgação</h5>
                        <p>Os Jardins Paloma apenas reservam o direito de divulgação de informações pessoais, mediante obrigação por lei, 
                            ou qualquer violação dos nossos Termos de Utilizacão.</p>

                        <h5>3) Cookies</h5>
                        <p>Os "cookies" são pequenos arquivos de texto que identificam o computador do Utilizador no servidor 
                            dos Jardins Paloma. Os cookies em si não identificam o utilizador individual, apenas o computador utilizado. 
                            Os cookies não são usados para recolher dados pessoais. Este Site utiliza cookies que servem para ajudar a determinar a utilidade, 
                            interesse e o número de acessos ao Site, permitindo uma navegação mais rápida e eficiente do mesmo, eliminando a necessidade de 
                            introduzir repetidamente as mesmas informações. Desta forma, os Jardins Paloma poderão prestar um serviço mais personalizado e à medida dos Utilizadores.
                            O utilizador tem, a qualquer momento, a possibilidade de configurar o seu dispositivo para aceitar todos os cookies, para notificá-lo quando 
                            um cookie é emitido ou para não receber quaisquer cookies. A forma como faz isso depende do navegador (web browser) que utiliza. 
                            Se aceitar cookies, eles podem permanecer no seu dispositivor por muitos anos, a menos que os elimine. 
                            Caso desligue os cookies poderá ficar limitado no que toca ao uso de sites em geral. Apresentamos, de seguida, uma lista dos cookies principais que utilizamos 
                            e informação sobre a função de cada um deles. A utilização de cookies por este sítio pressuporá sempre o seu prévio consentimento.</p>

                        <h5>4) Links para sites de terceiros</h5>
                        <p>Os links referenciados neste Site podem conduzir a sites externos. Os Jardins Paloma não se responsabilizam pelo conteúdo desses sites, 
                            nem dos sites com eles ligados ou neles referidos. A Jardins Paloma não se responsabiliza por danos resultantes de vírus que possam infetar o 
                            computador ou a rede do Utilizador, ou outros bens, em virtude do acesso ao site dos Jardins Paloma com transferência de conteúdos deste para o 
                            computador ou rede do Utilizador. A presente declaração de privacidade é aplicável unicamente à informação recolhida no Site dos Jardins Paloma.</p>

                        <h5>5) Reclamações</h5>
                        <p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, o Utilizador tem direito a 
                            apresentar uma reclamação à autoridade de controlo competente nos termos da lei, caso entenda que o 
                            tratamento dos seus dados pela Jardins Paloma viola o regime legal em vigor a cada momento.</p>


                        <h5>6) Questões</h5>
                        <p>Qualquer questão ou sugestão que o Utilizador queira colocar sobre a Poítica de Privacidade aqui apresentados, deverá ser enviada para <a href="mailto:info@jardinspaloma.com">info@jardinspaloma.com</a></p>

                        <div className="row">
                        <em>Data da última atualização: 16/12/2019</em>
                        </div>

            </section>
            </React.Fragment>

        )
    }
}


export default PrivacyPolicy