import React from 'react'
import './impacto.css'
import Header from '../../components/Header'
import impacto from '../../imgs/impacto.jpg'
import impactoposter from '../../imgs/poster.jpeg'

class Impacto extends React.Component{
    
    render(){
        return <React.Fragment>
                <Header heading="Eventos" subheading="Impacto II" imageBackground={impacto} />
                <section id="impacto">
                    <h2>Impacto - 2ª edição</h2>
                        <div className="row">
                            <div className="col span-1-of-2">
                                <img className="poster" src={impactoposter } width="450px" alt="Poster da segunda edição de Impacto"/>
                            </div>
                            <div className="col span-1-of-2 soundcloud-grid">
                                <iframe width="40%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/140863729&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                                <iframe width="40%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/9193851&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                                <iframe width="40%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/188718824&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                                <iframe width="40%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/120612741&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                            </div>
                        </div>
                            <p>Atuações de DJs nos Jardins Paloma, pelas 19h, <a href="https://www.facebook.com/Dj-Damost-197064280396357/" target="_blank" rel="noopener noreferrer">Dj Damost (MZ)</a>,  
                            <a href="https://www.facebook.com/Zimiadj" target="_blank" rel="noopener noreferrer">Cleyton Simon Zimia (MZ)</a>, 
                            <a href="https://www.facebook.com/DJ-Djuluka-572204522826254/" target="_blank" rel="noopener noreferrer">DJ Djuluka (MZ)</a>, 
                            <a href="https://www.facebook.com/DedeepJunkiesoul/" target="_blank" rel="noopener noreferrer">TrevOr K Deep_dedeepjunkie (ZA)</a>, 
                            <a href="https://soundcloud.com/erik-id-music" target="_blank" rel="noopener noreferrer">Erik ID (MZ)</a>,
                            <a href="https://soundcloud.com/kelvin_p" target="_blank" rel="noopener noreferrer">Kelvin Pedrinha (MZ)</a>,
                            <a href="https://www.facebook.com/ThePromoMixKing/" target="_blank" rel="noopener noreferrer">Deejay Flash (MZ)</a><br/>

                            Apoio de <a href="https://www.facebook.com/CDMCervejasdeMocambique/" target="_blank" rel="noopener noreferrer">CDM - Cervejas de Moçambique​</a>, 
                            <a href="https://www.instagram.com/picasso_eventos/" target="_blank" rel="noopener noreferrer">Picasso Negrão Eventos</a>, 
                            Produção <a href="https://www.instagram.com/xilavete_viriato/" target="_blank" rel="noopener noreferrer">XVM Entertainment</a>, <a href="https://www.facebook.com/royalezareal/" target="_blank" rel="noopener noreferrer">Royaleza Real</a><br/>
                            Patrocínio de <a href="https://www.facebook.com/rammultimediaoficial/" target="_blank" rel="noopener noreferrer">RAM Multimedia Moçambique</a></p>
                    {/* Videos */}
                    <div className="container impacto video">
                        <div className="col span-1-of-2">
                        <div class="fb-video" data-href="https://www.facebook.com/jardinspaloma/videos/2578490668841112/" data-width="500" data-show-text="false"><blockquote cite="https://developers.facebook.com/jardinspaloma/videos/2578490668841112/" class="fb-xfbml-parse-ignore"><a href="https://developers.facebook.com/jardinspaloma/videos/2578490668841112/"></a>
                            <a href="https://www.facebook.com/jardinspaloma/">Jardins Paloma Eventos</a> on Thursday, 12 September 2019</blockquote></div>
                        </div>
                        <div className="col span-1-of-2">
                            <div class="fb-video" data-href="https://www.facebook.com/jardinspaloma/videos/534572810683616/" data-width="500" data-show-text="false"><blockquote cite="https://developers.facebook.com/jardinspaloma/videos/534572810683616/" class="fb-xfbml-parse-ignore"><a href="https://developers.facebook.com/jardinspaloma/videos/534572810683616/"></a>
                                <a href="https://www.facebook.com/jardinspaloma/">Jardins Paloma Eventos</a> on Monday, 9 September 2019</blockquote></div>
                        </div>
                        {/* <div className="col span-2-of-2">
                            <div class="fb-video" data-href="https://www.facebook.com/jardinspaloma/videos/488234552000406/" data-width="700" data-show-text="false"><blockquote cite="https://developers.facebook.com/jardinspaloma/videos/488234552000406/" class="fb-xfbml-parse-ignore"><a href="https://developers.facebook.com/jardinspaloma/videos/488234552000406/"></a>
                                <a href="https://www.facebook.com/jardinspaloma/">Jardins Paloma Eventos</a> on Saturday, 14 September 2019</blockquote></div>
                        </div> */}
                    </div>   
                </section>
        </React.Fragment>
    }
}

export default Impacto