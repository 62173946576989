import React from 'react'
import Header from '../../components/Header'
import background from '../../imgs/background.jpg'
import './termos.css'

class Termos extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Header heading="Jardins Paloma" subheading="Termos de Utilização" imageBackground={background} >
                </Header>
                {/* <div className="container-welcome">
                    <div className="row">
                        <div className="col span-1-of-2 box">
                            <ul>
                            <li>TERMOS DE UTILIZAÇÃO</li>
                            <li></li>
                            <li>JARDINS PALOMA</li>
                            </ul>
                        </div>
                        <div className="col span-2-of-2 "> 
                        </div>
                    </div>
                </div> */}
                <section className="termos">
                <p className="first">Esse site é fornecido e operado pelos Jardins Paloma Eventos ("Jardins Paloma"), regendo-se a sua utilização por estes Termos de Utilização. 
                    O utilizador do Site (“Utilizador”) reconhece que ao usar este Site está a aceitar estes Termos de Utilização ("Termos").</p>

                <h5>1) Acesso ao Site</h5>
                <p>Os Jardins Paloma reserva o direito de a todo o tempo, suspender, parcial ou totalmente o acesso ao Site, atualizar, ou alterar qualquer parte dos 
                    Termos de Utilização aqui apresentados. O uso contínuo ou acesso ao site após a publicação de quaisquer alterações constitui a aceitação do Utilizador 
                    de tais alterações.</p>

                <h5>2) Condições Gerais</h5>
                <p>O Utilizador não está autorizado a reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Site, incluindo textos, imagens, 
                    gráficos e todas as outras informações e a forma em que são representadas no Site. A utilização de marcas e logótipos neste Site, assim como a 
                    disponibilização dos materiais existentes no Site, não concedem, nem podem ser interpretados como concedendo, permissão aos Utilizadores para utilizar, 
                    direta ou indiretamente, tais marcas, logótipos ou materiais.
                    Os títulos usados nesse acordo são incluídos apenas por conveniência e não limitam ou afetam os Termos.</p>

                <h5>3) Informações</h5>
                <p>As informações disponibilizadas no Site têm como motivo informar e esclarecer o Utilizador sobre a atividade dos Jardins Paloma. O material desse site é 
                    disponibilizado para fins informativos, que poderá contudo conter erros ou imprecisões. Os Jardins Paloma reservam o direito de modificar o conteúdo do 
                    Site a qualquer momento, sem qualquer obrigação prévia para tal.</p>

                <h5>4) Responsabilidade</h5>
                <p>Os Jardins Paloma não serão responsáveis por qualquer irregularidades do sistema ou falha (temporária ou permanente) do Site; nem se responsabilizarão por 
                    quaisquer danos resultantes da utilização indevida ou da impossibilidade de utilização do Site.</p>

                <h5>5) Links de Terceiros</h5>
                <p>Os Jardins Paloma poderão fornecer links para páginas de entidades terceiras, que não serão necessáriamente afiliadas aos Jardins Paloma. Não nos responsabilizamos 
                    por examinar ou avaliar o conteúdo ou precisão. Os Jardins Paloma não têm obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer 
                    outros materiais, produtos ou serviços de terceiros.</p>
               
                <p>6) O Utilizador deverá referir-se aos termos de utilização de terceiros, certificando-se das políticas e práticas dos mesmos.</p>

                <h5>7) Lei aplicável</h5>
                <p>Estes Termos de Utilização do Site encontram-se sujeitos à lei moçambicana.</p>

                <h5>8) Questões</h5>
                <p>Qualquer questão ou sugestão que o Utilizador queira colocar sobre os Termos de Utilização aqui apresentados, deverá ser enviada para <a href="mailto:info@jardinspaloma.com">info@jardinspaloma.com</a></p>

                <p>Data da última atualização: 16/12/2019</p>
                </section>
            </React.Fragment>
        )
    }
}


export default Termos